import { openPopupTY } from "./popupTY";
const sidebar = document.querySelector('.popupSO');
const sidebarBtns = document.querySelectorAll('.sidebar__btn');
const sidebarCloseBtn = document.querySelector('.popupSO__close');
const sidebarContent = document.querySelector('.popupSO__content');
const form = document.querySelector('.popupSO__form');
sidebarBtns.forEach(btn => btn.addEventListener('click', openSidebar));
form.addEventListener('submit', submitSidebarForm);

function openSidebar() {
    console.log('hello')
    sidebar.style.opacity = '1';
    sidebarContent.style.transform = 'translateX(0%)'
    sidebarCloseBtn.style.transform = 'translateX(0)';
    sidebar.style.pointerEvents = 'all';
    sidebarCloseBtn.addEventListener('click', closeSidebar);
}
function closeSidebar() {
    sidebarContent.style.transform = 'translateX(100%)'
    sidebarCloseBtn.style.transform = 'translateX(700px)';
    sidebar.style.pointerEvents = 'none';
    sidebar.style.opacity = '0';
}
async function submitSidebarForm(evt) {
    evt.preventDefault();
    const formData = new FormData(form);

    // Проверка обязательных полей
    if (!formData.get('request__name') || !formData.get('request__tel') || !formData.get('checkbox-accept')) {
        alert('Заполните обязательные поля!');
        return;
    }

    try {
        const response = await fetch('mail.php', {
            method: 'POST',
            body: formData
        });

        if (response.ok) {
            closeSidebar(); // Закрываем попап после успешной отправки
            openPopupTY();
            form.reset();
        } else {
            alert('Ошибка при отправке формы. Попробуйте позже.');
        }
    } catch (error) {
        console.error('Ошибка при отправке:', error);
        alert('Ошибка при отправке формы. Попробуйте позже.');
    }
}
export { openSidebar, closeSidebar, submitSidebarForm }