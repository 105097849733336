import { openPopupTY } from "./popupTY";
const subscribeForm = document.querySelector('.subscribe__form');
subscribeForm.addEventListener('submit', submitSubscribeForm);


async function submitSubscribeForm(evt) {
    evt.preventDefault();
    const formData = new FormData(subscribeForm);

    if (!formData.get('subscribe__email') || !formData.get('checkbox-accept')) {
        alert('Заполните обязательные поля!');
        return;
    }

    try {
        const response = await fetch('mail.php', {
            method: 'POST',
            body: formData
        });

        if (response.ok) {
            subscribeForm.reset();
            openPopupTY();
        } else {
            alert('Ошибка при отправке формы. Попробуйте позже.')
        }

    } catch (error) {
        console.error('Ошибка при отправке:', error);
        alert('Ошибка при отправке формы. Попробуйте позже.');
    }
}

export { submitSubscribeForm }
