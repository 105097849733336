const popupTY = document.querySelector('.popupTY');
const closePopupTYbtn = document.querySelector('.popupTY__close');


function openPopupTY() {
    popupTY.style.opacity = '1';
    popupTY.style.pointerEvents = 'all'
    closePopupTYbtn.addEventListener('click', closePopupTY);
}
function closePopupTY() {
    popupTY.style.opacity = '0';
    popupTY.style.pointerEvents = 'none'
}
export { openPopupTY }