import { openPopupTY } from "./popupTY";
const requestForm = document.querySelector('.request__form').querySelector('form');
requestForm.addEventListener('submit', submitRequestForm);


async function submitRequestForm(evt) {
    evt.preventDefault();
    const formData = new FormData(requestForm);

    // Проверка обязательных полей
    if (!formData.get('request__name') || !formData.get('request__tel') || !formData.get('checkbox-accept')) {
        alert('Заполните обязательные поля!');
        return;
    }

    try {
        const response = await fetch('mail.php', {
            method: 'POST',
            body: formData
        });

        if (response.ok) {
            // closePopup(); // Закрываем попап после успешной отправки
            requestForm.reset();
            openPopupTY()
        } else {
            alert('Ошибка при отправке формы. Попробуйте позже.');
        }
    } catch (error) {
        console.error('Ошибка при отправке:', error);
        alert('Ошибка при отправке формы. Попробуйте позже.');
    }
}
export { submitRequestForm }