const point = document.querySelectorAll('.genplan__point-circle');
point.forEach(point => point.addEventListener('click', openPointModal))
const closeGenplanBtn = document.querySelectorAll('.close-genplan-btn');

function openPointModal() {
    const pointModal = this.closest('.genplan__point').querySelector('.genplan__modal');
    const closeModal = this.closest('.genplan__point').querySelector('.close-genplan-btn');
    const pointCircle = this.closest('.genplan__point').querySelector('.genplan__point-circle');
    const pointCirlces = document.querySelectorAll('.genplan__point-circle');
    const pointModals = document.querySelectorAll('.genplan__modal');
    pointCirlces.forEach(point => point.classList.remove('genplan__point-green'))
    pointModals.forEach(point => point.style.opacity = '0');
    pointCircle.classList.add('genplan__point-green');
    pointModal.style.display = 'block'
    pointCircle.style.display = 'flex'
    pointModal.style.opacity = '1';
    pointModal.style.visibility = 'visible'; 
    closeModal.addEventListener('click', closePointModal);

}
function closePointModal() {
    const pointModal = this.closest('.genplan__point').querySelector('.genplan__modal');
    const pointCircle = this.closest('.genplan__point').querySelector('.genplan__point-circle');
    pointCircle.classList.remove('genplan__point-green');
    pointModal.style.opacity = '0';
    pointModal.style.visibility = 'hidden'
    pointModal.style.displa = 'none';
    pointCircle.style.displa = 'none';
    console.log('hello')
}
export { openPointModal }
