import Swiper from "swiper";
import { Navigation} from 'swiper/modules';

function initProjectsSwiper() {
    const projectsSwiper = new Swiper(".projectsSwiper", {
        modules: [Navigation],
        slidesPerView: 1.1,
        spaceBetween: 21,
        speed: 600, 
        navigation: {
          nextEl: "#projects-swiper-next",
          prevEl: "#projects-swiper-prev",
        },
        breakpoints: {
            1024: {
              slidesPerView: 'auto',
            },
          },
        mousewheel: true,
        keyboard: true,
    });
    
    
}
const cardControls = document.querySelectorAll('.projects__card-control');

cardControls.forEach(card => card.addEventListener('click', projectsCardControls));

function projectsCardControls() {
    const hiddenPart = this.closest('.projects__card-content').querySelector('.projects__card-hidden');
    const borderPart = this.closest('.projects__card-content').querySelector('.projects__card-border');
    console.log(borderPart)
    if (hiddenPart.classList.contains('closed')) {
        hiddenPart.style.transform = 'translateY(-108px)';
        hiddenPart.style.opacity = '1';
        borderPart.style.opacity = '1';
        hiddenPart.style.visibility = 'visible';
        borderPart.style.visibility = 'visible';
        hiddenPart.classList.remove('closed')
    } else {
        hiddenPart.style.transform = 'translateY(20px)';
        hiddenPart.style.opacity = '0';
        borderPart.style.opacity = '0';
        hiddenPart.style.visibility = 'hidden';
        borderPart.style.visibility = 'hidden';
        hiddenPart.classList.add('closed')
    }
    console.log(hiddenPart);
}

export { initProjectsSwiper, projectsCardControls, toggleCardContent }