import Swiper from 'swiper';
import { Navigation, Controller } from 'swiper/modules';
import AOS from 'aos';

function initProductsSwipers() {
  const swiperImgEl = document.querySelector('.swiper-img');
  const swiperTextEl = document.querySelector('.swiper-text');

  const swiperImgSlides = swiperImgEl ? swiperImgEl.querySelectorAll('.swiper-slide').length : 0;
  const swiperTextSlides = swiperTextEl ? swiperTextEl.querySelectorAll('.swiper-slide').length : 0;

  const isLoopAllowedImg = swiperImgSlides > 1; 
  const isLoopAllowedText = swiperTextSlides > 1;

  const swiperImg = new Swiper('.swiper-img', {
    modules: [Navigation, Controller],
    // loop: isLoopAllowedImg,
    slidesPerView: 1.14,
    spaceBetween: 16,
    speed: 600, 
    effect: 'fade', 
    preventInteractionOnTransition: true,
    slidesOffsetAfter:
									(document.documentElement.scrollWidth / 100) * 10 + 20,
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 190,
        slidesOffsetAfter: 0
      },
    },
    navigation: {
      nextEl: "#products-right",
      prevEl: "#products-left",
    },
    // mousewheel: true,
    // keyboard: true,
  });

  const swiperText = new Swiper('.swiper-text', {
    modules: [Navigation, Controller],
    // loop: isLoopAllowedText,
    slidesPerView: 1,
    preventInteractionOnTransition: true,
    // mousewheel: true,
    // keyboard: true,
  });

  swiperImg.controller.control = swiperText;
  swiperText.controller.control = swiperImg;
}

export { initProductsSwipers };
